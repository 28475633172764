import React from "react";
import styled from "styled-components";
import SoftIcon from "../../sharedComponents/softIcon";
import * as SOFTS_icons from "../../../assets/datas/profilDatas";

const softs = () => {
    return (
        <Softs>
            <div className="softs">
                <div>
                    <SoftIcon
                        iconName={SOFTS_icons.iReactJs}
                        iconAlt="Icone de ReactJS"
                    />
                    <SoftIcon
                        iconName={SOFTS_icons.iGit}
                        iconAlt="Icone de Git"
                    />
                    <SoftIcon
                        iconName={SOFTS_icons.iReactNative}
                        iconAlt="Icone de ReactNative"
                    />
                </div>
                <div>
                    <SoftIcon
                        iconName={SOFTS_icons.iIllustrator}
                        iconAlt="Icone du logiciel Illustrator"
                    />
                    <SoftIcon
                        iconName={SOFTS_icons.iPhotoshop}
                        iconAlt="Icone du logiciel Photoshop"
                    />
                    <SoftIcon
                        iconName={SOFTS_icons.iAdobeXD}
                        iconAlt="Icone du logiciel AdobeXD"
                    />
                    <SoftIcon
                        iconClassName="figmaIconToHide"
                        iconName={SOFTS_icons.iFigma}
                        iconAlt="Icone du logiciel Figma"
                    />
                </div>
                <div>
                    <SoftIcon
                        iconName={SOFTS_icons.iSpringBoot}
                        iconAlt="Icone du logiciel SpringBoot"
                    />
                    <SoftIcon
                        iconClassName="javaIconToHide"
                        iconName={SOFTS_icons.iJava}
                        iconAlt="Icone du logiciel Java"
                    />
                    <SoftIcon
                        iconClassName="figmaIconToShow"
                        iconName={SOFTS_icons.iFigma}
                        iconAlt="Icone du logiciel Figma"
                    />
                    <SoftIcon
                        iconName={SOFTS_icons.iSpringBootSecurity}
                        iconAlt="Icone du logiciel SpringBootSecurity"
                    />
                </div>
            </div>
        </Softs>
    );
};

export default softs;

const Softs = styled.div`
    .softs {
        margin: 20px 10px;
    }

    .softs > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        width: 130px;
        margin: 3px;
    }

    .figmaIconToHide,
    .javaIconToHide {
        display: block;
    }
    .figmaIconToShow {
        display: none;
    }

    @media screen and (max-width: 450px) {
        .softs img {
            width: 100px;
        }
        .figmaIconToHide,
        .javaIconToHide {
            display: none;
        }
        .figmaIconToShow {
            display: block;
        }
    }

    @media screen and (min-width: 800px) {
        .softs {
            margin: 50px 0px;
        }
        img {
            margin: 10px;
        }
    }
`;
