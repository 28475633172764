import React from "react";
import styled from "styled-components";
import projectsDatas from "../../../../assets/datas/projectsDatas";
import "react-slideshow-image/dist/styles.css";

const SlideSoloPic = ({ pageName }) => {
    return (
        <SlideSoloPicStyle>
            {projectsDatas.map(
                (project) =>
                    project.urlName === pageName && (
                        <div className="soloPic" key={project.id}>
                            <img
                                src={project.sliderIntroPics}
                                alt={project.sliderDesigns[0].title}
                            />
                        </div>
                    )
            )}
        </SlideSoloPicStyle>
    );
};

export default SlideSoloPic;

const SlideSoloPicStyle = styled.div`
    .soloPic {
        padding: 10px 0px 15px;
    }

    .soloPic img {
        width: 100%;
        max-height: 550px;
    }

    @media screen and (min-width: 800px) {
        .soloPic img {
            max-height: 650px;
        }
    }

    @media screen and (min-width: 1000px) {
        .soloPic img {
            width: auto;
            height: 550px;
        }
    }
`;
