import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const softIcon = ({ iconName, iconAlt, iconClassName }) => {
    return (
        <AnimationOnScroll
            animateIn="rollIn"
            animateOut="rollOut"
            className={iconClassName}
        >
            <img src={iconName} alt={iconAlt} />
        </AnimationOnScroll>
    );
};

export default softIcon;
