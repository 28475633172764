import ReactJS from "../icons/reactJS.svg";
import Wordpress from "../icons/wordpress.svg";
import Illustrator from "../icons/illustrator.svg";
import Photoshop from "../icons/photoshop.svg";
import AdobeXD from "../icons/adobeXD.svg";
import Figma from "../icons/figma.svg";
import Git from "../icons/git.svg";
import ReactNative from "../icons/reactNative.svg";
import SpringBoot from "../icons/springBoot.svg";
import SpringBootSecurity from "../icons/springBootSecurity.svg";
import Java from "../icons/java.svg";

import Planete from "../icons/earthIcon.svg";
import Des from "../icons/dicesIcon.svg";

export const iReactJs = ReactJS;
export const iWordpress = Wordpress;
export const iIllustrator = Illustrator;
export const iPhotoshop = Photoshop;
export const iAdobeXD = AdobeXD;
export const iFigma = Figma;
export const iGit = Git;
export const iReactNative = ReactNative;
export const iSpringBoot = SpringBoot;
export const iSpringBootSecurity = SpringBootSecurity;
export const iJava = Java;

const profilDatas = [
    {
        categoryName: "skills",
        skills: [
            {
                id: "uxdesign",
                title: "Design U.X.",
                description: [
                    "Benchmark",
                    "Persona",
                    "Rush Papier",
                    "User Flow",
                    "Prototypage Mobile Et Desktop",
                ],
            },
            {
                id: "uidesign",
                title: "Design U.I.",
                description: [
                    "Colorimétrie",
                    "Typographie",
                    "Mise En Page",
                    "Mood Board",
                    "Charte Graphique",
                    "Mock-Up",
                ],
            },
            {
                id: "fdev",
                title: "Développement front-end",
                description: [
                    "HTML",
                    "CSS",
                    "React JS",
                    "React Native",
                    "Tailwind",
                    "Bootstrap",
                    "Wordpress",
                ],
            },
            {
                id: "bdev",
                title: "Développement back-end",
                description: [
                    "Java",
                    "PostgresSQL",
                    "Spring Boot",
                    "Spring Boot Security",
                    "Micro Service",
                ],
            },
            {
                id: "tools",
                title: "Outils de travail",
                description: [
                    ["Slack", "Teams", "Discord"],
                    ["Miro", "Trello", "Jira"],
                    ["Chrome", "Firefox", "Opera"],
                    ["PgAdmin", "DBeaver"],
                    ["Intellij", "Visual Studio Code"],
                    ["Postman"],
                ],
            },
        ],
    },

    {
        id: "historic02",
        categoryName: "myhistory",
        myhistory: [
            {
                id: "cedf",
                year: "01/2023 - 06/2023",
                descriptionName:
                    "Développeur full-stack au Centre Européen de Formation",
                descriptionPlace: "Lille _ Nord",
            },
            {
                id: "cda",
                year: "09/2021 - 01/2023",
                descriptionName:
                    "Alternance Concepteur Développeur d'Applications à INSY2S",
                descriptionPlace: "Lille _ Nord",
            },
            {
                id: "javaJee",
                year: "2021",
                descriptionName: "Formation Java JEE",
                descriptionPlace: "Roubaix _ Nord",
            },
            {
                id: "spwm",
                year: "2021",
                descriptionName: "Stage Programmeur Web et Web-mobile à INSY2S",
                descriptionPlace: "Lille _ Nord",
            },
            {
                id: "pwm",
                year: "2021",
                descriptionName: "Formation Programmeur Web et Web-mobile",
                descriptionPlace: "Beauvais _ Oise",
            },
            {
                id: "tctChalls",
                year: "2020 - 2022",
                descriptionName:
                    "Participation aux challenges The Cacatoes Theory",
                descriptionPlace: "En ligne",
            },
            {
                id: "autoEntrepreneur",
                year: "2020",
                descriptionName: "Création de statut auto-entrepreneur",
                descriptionPlace: "Beauvais _ Oise",
            },
            {
                id: "iacess",
                year: "2020",
                descriptionName:
                    "Réalisation du site internet pour l'association IACESS",
                descriptionPlace: "Paris _ Ile de France",
            },
            {
                id: "stage2",
                year: "2019",
                descriptionName:
                    "Stage et réalisation du site internet pour DESIM (5\u00a0mois)",
                descriptionPlace: "Ivry sur seine",
            },
            {
                id: "stage1",
                year: "2019",
                descriptionName: "Stage Web Designer",
                description: "chez J-S QUETTIER (4\u00a0mois)",
                descriptionPlace: "Beauvais _ Oise",
            },
            {
                id: "design",
                year: "2019",
                descriptionName: "Formation bac+2",
                description:
                    "Web Designer UX\u00a0_\u00a0UI\u00a0_\u00a0Intégration",
                descriptionPlace: "Beauvais _ Oise",
            },
            {
                id: "3d",
                year: "2017",
                descriptionName:
                    "Formation Infographiste Effets Spéciaux 3D (3\u00a0ans)",
                descriptionPlace: "Mons _ Belgique",
            },
            {
                id: "parc",
                year: "2014",
                descriptionName: "Opératrice de Parc d’attraction (1\u00a0an)",
                descriptionPlace: "St Paul _ Oise",
            },
        ],
    },

    {
        id: "hobbies03",
        categoryName: "Hobbies",
        myhobbies: [
            {
                id: "meetings",
                icon: Planete,
                kind: "Rencontre internationale d’ordre culturel. (TOEIC : 940) Particulièrement culture asiatique (Corée du Sud)",
            },
            {
                id: "games",
                icon: Des,
                kind: "Escape game, jeux de rôle, jeux de société, jeux vidéo",
            },
        ],
    },
];

export default profilDatas;
