import React from "react";
import styled from "styled-components";
import Separator from "../../sharedComponents/separator";
import Title from "../../sharedComponents/titles";
import profilDatas from "../../../assets/datas/profilDatas";

const skills = () => {
    return (
        <Skills>
            <Separator type="profileSeparator" separatorTitle="Compétences" />
            <ul className="skills">
                {profilDatas.map(
                    (categories) =>
                        categories.skills &&
                        categories.skills.map((subCategories) => (
                            <div key={subCategories.id}>
                                {subCategories.title !== "Outils de travail" ? (
                                    <>
                                        <div className="skillsContent">
                                            <div>
                                                <Title
                                                    type="H3"
                                                    theme="h3__letters"
                                                    content={
                                                        subCategories.title
                                                    }
                                                />
                                            </div>
                                            <div>
                                                {subCategories.description.map(
                                                    (element) => (
                                                        <div
                                                            key={element}
                                                            className="skillsPlacements"
                                                        >
                                                            <li>{element}</li>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <div className="div__line_skillsLine"></div>
                                    </>
                                ) : (
                                    <>
                                        <div className="skillsContent">
                                            <div>
                                                <Title
                                                    type="H3"
                                                    theme="h3__letters"
                                                    content={
                                                        subCategories.title
                                                    }
                                                />
                                            </div>
                                            <div>
                                                {subCategories.description.map(
                                                    (toolCategories, ind) => (
                                                        <div
                                                            key={toolCategories}
                                                            className="toolPlacements"
                                                        >
                                                            {toolCategories
                                                                .slice(
                                                                    0,
                                                                    toolCategories.length -
                                                                        1
                                                                )
                                                                .map(
                                                                    (
                                                                        tool,
                                                                        index
                                                                    ) => (
                                                                        <li
                                                                            key={
                                                                                tool +
                                                                                ind
                                                                            }
                                                                        >
                                                                            {`${tool} -`}
                                                                        </li>
                                                                    )
                                                                )}
                                                            {toolCategories
                                                                .slice(
                                                                    toolCategories.length -
                                                                        1,
                                                                    toolCategories.length
                                                                )
                                                                .map(
                                                                    (
                                                                        tool,
                                                                        index
                                                                    ) => (
                                                                        <li
                                                                            key={
                                                                                tool +
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                tool
                                                                            }
                                                                        </li>
                                                                    )
                                                                )}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <div className="div__line_skillsLine"></div>
                                    </>
                                )}
                            </div>
                        ))
                )}
            </ul>
        </Skills>
    );
};

export default skills;

const Skills = styled.div`
    .skills {
        font-size: 1rem;
        padding-inline-start: 0px;
        text-align: left;
        width: 265px;
        margin: 0 auto;
    }

    .skills li {
        font-family: "montserrat_medium";
        font-size: 1.1rem;
        font-weight: normal;
        line-height: 1.2;
    }

    .skillsContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .skills .div__line_skillsLine {
        display: none;
    }
    .skillsPlacements {
        margin: 5px 0px 5px 65px;
    }
    .toolPlacements {
        margin: 10px 0px 10px 65px;
    }
    .toolPlacements li {
        margin-right: 5px;
    }

    @media screen and (min-width: 800px) {
        .skills {
            width: 100%;
            list-style: none;
        }

        .skills > div {
            display: flex;
            flex-direction: column;
        }

        .skillsContent {
            flex-direction: row;
            align-items: center;
        }

        .skillsContent > div:first-of-type {
            flex-basis: 50%;
            display: flex;
            justify-content: flex-end;
        }

        .skillsContent > div:last-of-type {
            flex-basis: 50%;
        }

        .toolPlacements {
            margin: 5px 0px 5px 65px;
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
        }

        .skills .div__line_skillsLine {
            display: block;
            width: 700px;
            align-self: center;
            margin: 20px 0px 20px 20px;
            height: 1px;
            border-radius: 5px;
            background-color: #ff6600;
        }
    }

    @media screen and (min-width: 1200px) {
        .skills .div__line_skillsLine {
            width: 750px;
            margin: 20px 0px 20px 20px;
        }
    }
`;
