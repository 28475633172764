import cinecinema from "../../assets/imgs/previews/cinecinema.jpg";
import cinecinemaIntroDesktop from "../../assets/imgs/description_website/cinecinema/cinecinema_intro_desktop.jpg";
import cinecinemaMiniature_01 from "../../assets/imgs/description_website/cinecinema/miniature_01.png";
import cinecinemaMiniature_02 from "../../assets/imgs/description_website/cinecinema/miniature_02.png";
import cinecinemaMiniature_03 from "../../assets/imgs/description_website/cinecinema/miniature_03.png";
import cinecinemaMiniature_04 from "../../assets/imgs/description_website/cinecinema/miniature_04.png";
import cinecinemaMiniature_05 from "../../assets/imgs/description_website/cinecinema/miniature_05.png";
import cinecinemaMiniature_06 from "../../assets/imgs/description_website/cinecinema/miniature_06.png";
import cinecinemaMiniature_07 from "../../assets/imgs/description_website/cinecinema/miniature_07.png";
import cinecinemaMiniature_08 from "../../assets/imgs/description_website/cinecinema/miniature_08.png";
import cinecinemaMiniature_09 from "../../assets/imgs/description_website/cinecinema/miniature_09.png";
import cinecinemaPicture_01 from "../../assets/imgs/description_website/cinecinema/big_01.jpg";
import cinecinemaPicture_02 from "../../assets/imgs/description_website/cinecinema/big_02.jpg";
import cinecinemaPicture_03 from "../../assets/imgs/description_website/cinecinema/big_03.jpg";
import cinecinemaPicture_04 from "../../assets/imgs/description_website/cinecinema/big_04.jpg";
import cinecinemaPicture_05 from "../../assets/imgs/description_website/cinecinema/big_05.jpg";
import cinecinemaPicture_06 from "../../assets/imgs/description_website/cinecinema/big_06.jpg";
import cinecinemaPicture_07 from "../../assets/imgs/description_website/cinecinema/big_07.jpg";
import cinecinemaPicture_08 from "../../assets/imgs/description_website/cinecinema/big_08.jpg";
import cinecinemaPicture_09 from "../../assets/imgs/description_website/cinecinema/big_09.jpg";

import sesame from "../../assets/imgs/previews/sesame.jpg";
import sesameIntroDesktop from "../../assets/imgs/description_website/sesame/sesame_intro_desktop.png";
import sesameMiniature_01 from "../../assets/imgs/description_website/sesame/miniature_01.png";
import sesameMiniature_02 from "../../assets/imgs/description_website/sesame/miniature_02.png";
import sesameMiniature_03 from "../../assets/imgs/description_website/sesame/miniature_03.png";
import sesameMiniature_04 from "../../assets/imgs/description_website/sesame/miniature_04.png";
import sesameMiniature_05 from "../../assets/imgs/description_website/sesame/miniature_05.png";
import sesameMiniature_06 from "../../assets/imgs/description_website/sesame/miniature_06.png";
import sesameMiniature_07 from "../../assets/imgs/description_website/sesame/miniature_07.png";
import sesameMiniature_08 from "../../assets/imgs/description_website/sesame/miniature_08.png";
import sesameMiniature_09 from "../../assets/imgs/description_website/sesame/miniature_09.png";
import sesamePicture_01 from "../../assets/imgs/description_website/sesame/big_01.jpg";
import sesamePicture_02 from "../../assets/imgs/description_website/sesame/big_02.jpg";
import sesamePicture_03 from "../../assets/imgs/description_website/sesame/big_03.jpg";
import sesamePicture_04 from "../../assets/imgs/description_website/sesame/big_04.jpg";
import sesamePicture_05 from "../../assets/imgs/description_website/sesame/big_05.jpg";
import sesamePicture_06 from "../../assets/imgs/description_website/sesame/big_06.jpg";
import sesamePicture_07 from "../../assets/imgs/description_website/sesame/big_07.jpg";
import sesamePicture_08 from "../../assets/imgs/description_website/sesame/big_08.jpg";
import sesamePicture_09 from "../../assets/imgs/description_website/sesame/big_09.jpg";

import rolesdice from "../../assets/imgs/previews/roledice.jpg";
import rolesdiceIntroDesktop from "../../assets/imgs/description_website/rolesdice/rolesdice_intro_desktop.png";
import rolesdiceMiniature_01 from "../../assets/imgs/description_website/rolesdice/miniature_01.png";
import rolesdicePicture_01 from "../../assets/imgs/description_website/rolesdice/big_01.jpg";

import fujisport from "../../assets/imgs/previews/fujisport.jpg";
import fujisportIntroMobile from "../../assets/imgs/description_website/fujisport/fujisport_intro_mobile.jpg";
import fujisportIntroTablet from "../../assets/imgs/description_website/fujisport/fujisport_intro_tablet.jpg";
import fujisportIntroDesktop from "../../assets/imgs/description_website/fujisport/fujisport_intro_desktop.jpg";
import fujisportMiniature_01 from "../../assets/imgs/description_website/fujisport/miniature_01.png";
import fujisportMiniature_02 from "../../assets/imgs/description_website/fujisport/miniature_02.png";
import fujisportMiniature_03 from "../../assets/imgs/description_website/fujisport/miniature_03.png";
import fujisportMiniature_04 from "../../assets/imgs/description_website/fujisport/miniature_04.png";
import fujisportMiniature_05 from "../../assets/imgs/description_website/fujisport/miniature_05.png";
import fujisportMiniature_06 from "../../assets/imgs/description_website/fujisport/miniature_06.png";
import fujisportMiniature_07 from "../../assets/imgs/description_website/fujisport/miniature_07.png";
import fujisportMiniature_08 from "../../assets/imgs/description_website/fujisport/miniature_08.png";
import fujisportMiniature_09 from "../../assets/imgs/description_website/fujisport/miniature_09.png";
import fujisportMiniature_10 from "../../assets/imgs/description_website/fujisport/miniature_10.png";
import fujisportPicture_01 from "../../assets/imgs/description_website/fujisport/big_01.jpg";
import fujisportPicture_02 from "../../assets/imgs/description_website/fujisport/big_02.jpg";
import fujisportPicture_03 from "../../assets/imgs/description_website/fujisport/big_03.jpg";
import fujisportPicture_04 from "../../assets/imgs/description_website/fujisport/big_04.jpg";
import fujisportPicture_05 from "../../assets/imgs/description_website/fujisport/big_05.jpg";
import fujisportPicture_06 from "../../assets/imgs/description_website/fujisport/big_06.jpg";
import fujisportPicture_07 from "../../assets/imgs/description_website/fujisport/big_07.jpg";
import fujisportPicture_08 from "../../assets/imgs/description_website/fujisport/big_08.jpg";
import fujisportPicture_09 from "../../assets/imgs/description_website/fujisport/big_09.jpg";
import fujisportPicture_10 from "../../assets/imgs/description_website/fujisport/big_10.jpg";

import desim from "../../assets/imgs/previews/desim.jpg";
import desimIntroMobile from "../../assets/imgs/description_website/desim/desim_intro_mobile.jpg";
import desimIntroTablet from "../../assets/imgs/description_website/desim/desim_intro_tablet.jpg";
import desimIntroDesktop from "../../assets/imgs/description_website/desim/desim_intro_desktop.jpg";
import desimMiniature_01 from "../../assets/imgs/description_website/desim/miniature_01.png";
import desimMiniature_02 from "../../assets/imgs/description_website/desim/miniature_02.png";
import desimMiniature_03 from "../../assets/imgs/description_website/desim/miniature_03.png";
import desimMiniature_04 from "../../assets/imgs/description_website/desim/miniature_04.png";
import desimMiniature_05 from "../../assets/imgs/description_website/desim/miniature_05.png";
import desimMiniature_06 from "../../assets/imgs/description_website/desim/miniature_06.png";
import desimMiniature_07 from "../../assets/imgs/description_website/desim/miniature_07.png";
import desimPicture_01 from "../../assets/imgs/description_website/desim/big_01.jpg";
import desimPicture_02 from "../../assets/imgs/description_website/desim/big_02.jpg";
import desimPicture_03 from "../../assets/imgs/description_website/desim/big_03.jpg";
import desimPicture_04 from "../../assets/imgs/description_website/desim/big_04.jpg";
import desimPicture_05 from "../../assets/imgs/description_website/desim/big_05.jpg";
import desimPicture_06 from "../../assets/imgs/description_website/desim/big_06.jpg";
import desimPicture_07 from "../../assets/imgs/description_website/desim/big_07.jpg";

import ak2i from "../../assets/imgs/previews/ak2i.jpg";
import ak2iIntroDesktop from "../../assets/imgs/description_website/ak2i/ak2i_intro_desktop.png";
import ak2iMiniature_01 from "../../assets/imgs/description_website/ak2i/miniature_01.png";
import ak2iPicture_01 from "../../assets/imgs/description_website/ak2i/big_01.jpg";

import sncf from "../../assets/imgs/previews/sncf.jpg";
import sncfIntroMobile from "../../assets/imgs/description_website/sncf/sncf_intro_mobile.jpg";
import sncfMiniature_01 from "../../assets/imgs/description_website/sncf/miniature_01.png";
import sncfMiniature_02 from "../../assets/imgs/description_website/sncf/miniature_02.png";
import sncfPicture_01 from "../../assets/imgs/description_website/sncf/big_01.jpg";
import sncfPicture_02 from "../../assets/imgs/description_website/sncf/big_02.jpg";

import frisson from "../../assets/imgs/previews/frisson.jpg";
import frissonIntroTablet from "../../assets/imgs/description_website/frisson/frisson_intro_tablet.jpg";
import frissonMiniature_01 from "../../assets/imgs/description_website/frisson/miniature_01.png";
import frissonMiniature_02 from "../../assets/imgs/description_website/frisson/miniature_02.png";
import frissonMiniature_03 from "../../assets/imgs/description_website/frisson/miniature_03.png";
import frissonPicture_01 from "../../assets/imgs/description_website/frisson/big_01.jpg";
import frissonPicture_02 from "../../assets/imgs/description_website/frisson/big_02.jpg";
import frissonPicture_03 from "../../assets/imgs/description_website/frisson/big_03.jpg";

import iacess from "../../assets/imgs/previews/iacess.jpg";
import iacessIntroMobile from "../../assets/imgs/description_website/iacess/iacess_intro_mobile.jpg";
import iacessIntroTablet from "../../assets/imgs/description_website/iacess/iacess_intro_tablet.jpg";
import iacessIntroDesktop from "../../assets/imgs/description_website/iacess/iacess_intro_desktop.jpg";
import iacessMiniature_01 from "../../assets/imgs/description_website/iacess/miniature_01.png";
import iacessMiniature_02 from "../../assets/imgs/description_website/iacess/miniature_02.png";
import iacessPicture_01 from "../../assets/imgs/description_website/iacess/big_01.jpg";
import iacessPicture_02 from "../../assets/imgs/description_website/iacess/big_02.jpg";

import circularKitchen from "../../assets/imgs/previews/kitchen.jpg";
import cKPicture_01 from "../../assets/imgs/description_3d/kitchen.jpg";

import squareKitchen from "../../assets/imgs/previews/square_kitchen.jpg";
import sKPicture_01 from "../../assets/imgs/description_3d/square_kitchen.jpg";

import circularLoft from "../../assets/imgs/previews/circular_apartment_plan.jpg";
import cLPicture_01 from "../../assets/imgs/description_3d/circular_apartment_plan.jpg";

import carsRacing from "../../assets/imgs/previews/cars.jpg";

import dojo from "../../assets/imgs/previews/dojo.jpg";

import lanterns from "../../assets/imgs/previews/lanterns.jpg";

import stillLife from "../../assets/imgs/previews/still_life.jpg";
import sLPicture_01 from "../../assets/imgs/description_3d/still_life_1.jpg";
import sLPicture_02 from "../../assets/imgs/description_3d/still_life_2.jpg";

import knight from "../../assets/imgs/previews/knight.jpg";
import knightPicture_01 from "../../assets/imgs/description_3d/knight.jpg";

import origami from "../../assets/imgs/previews/origami.jpg";

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

const projetcsDatas = [
    {
        urlName: "rolesdice",
        name: "Roles dice",
        id: "rolesdice",
        index: 17,
        microCover: rolesdice,
        microDescription_projectType: "Application",
        microDescription_skillsUsed: "UX - UI Design + Dév. fullstack",
        microDescription_year: 2023,
        microDescription_caseStudy: false,
        fullProjectName: "Application de jeux de rôle",
        fullProjectDescription:
            "Roles dice est un projet destiné aux joueur de jeux de rôle. La plateforme doit permettre de voir les autres parties des joueurs en plein jeux, créer ses propres parties facilement et les personnaliser au maximum.",
        fullProjectIssue: [
            "Présenter de manière la plus claire et accessible possible le grand volume de fonctionnalité et de personnalisation.",
            "S'assurer que les joueurs auront toujours accès au site, indépendamment du nombre de personne connecté.",
        ],
        fullProjectSolution: [
            "Créer des sections clairement délimitées et des sliders pour faciler l'accès et la lisibilité.",
            "Faire le développement backend en multi-service afin d'éviter les surcharges",
            "Développer les paramétrages pour le jeu et pour chaques joueurs dès la création d'une partie.",
            "Développer un espace dédié pour jouer avec différentes phases de jeu.",
            "Développer un espace viewer, avec Chat intégré, pour les spectateurs qui veulent assister à une partie sans y jouer.",
        ],
        sliderIntroPics: [rolesdiceIntroDesktop],
        sliderIntroPicNbs: 1,
        sliderDesigns: [
            {
                id: 0,
                title: "Accueil",
                miniature: rolesdiceMiniature_01,
                fullDesign: rolesdicePicture_01,
            },
        ],
    },

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "cinecinema",
        name: "Cinecinema",
        id: "cinecinema",
        index: 16,
        microCover: cinecinema,
        microDescription_projectType: "Application web",
        microDescription_skillsUsed: "UX - UI Design + Dév. front",
        microDescription_year: 2021,
        microDescription_caseStudy: true,
        fullProjectName: "Application de type allocine.com",
        fullProjectDescription:
            "Cinécinéma est une application similaire au site internet d'allocine.com. Ce projet a été réalisé pendant mon stage de développeur web, web-mobile chez INSY2S.",
        fullProjectIssue: [
            "Réaliser les wireframes et maquettes en un minimum de temps pour laisser place au développement.",
            "Développer le front en utilisant une librairie CSS (Tailwind) encore inconnue, puis développer le back, avec une courte deadline.",
        ],
        fullProjectSolution: [
            "Consacrer plus de temps aux wireframes et à la maquette de la page d'accueil, afin de plus avoir qu'à appliquer le même style au reste des wireframes.",
            "Investir un peu plus de temps sur la prise en main de Tailwind pour en gagner sur la mise en place de composants réutilisables dont le CSS est déjà défini. Par exemple les boutons cliquables.",
            "Répartir les tâches entre toute l'équipe, et en monitorer l'avancement ou les difficultés grâce à la méthode SCRUM.",
        ],
        sliderIntroPics: [cinecinemaIntroDesktop],
        sliderIntroPicNbs: 1,
        sliderDesigns: [
            {
                id: 0,
                title: "Accueil",
                miniature: cinecinemaMiniature_01,
                fullDesign: cinecinemaPicture_01,
            },
            {
                id: 1,
                title: "Résultat de recherche",
                miniature: cinecinemaMiniature_02,
                fullDesign: cinecinemaPicture_02,
            },
            {
                id: 2,
                title: "Détail d'un film - Résumé",
                miniature: cinecinemaMiniature_03,
                fullDesign: cinecinemaPicture_03,
            },
            {
                id: 3,
                title: "Détail d'un film - Bandes annonces",
                miniature: cinecinemaMiniature_04,
                fullDesign: cinecinemaPicture_04,
            },
            {
                id: 4,
                title: "Détail d'un film - Casting",
                miniature: cinecinemaMiniature_05,
                fullDesign: cinecinemaPicture_05,
            },
            {
                id: 5,
                title: "Détail d'un film - Reviews",
                miniature: cinecinemaMiniature_06,
                fullDesign: cinecinemaPicture_06,
            },
            {
                id: 6,
                title: "Paramètre de profil",
                miniature: cinecinemaMiniature_07,
                fullDesign: cinecinemaPicture_07,
            },
            {
                id: 7,
                title: "Favoris",
                miniature: cinecinemaMiniature_08,
                fullDesign: cinecinemaPicture_08,
            },
            {
                id: 8,
                title: "Profil public",
                miniature: cinecinemaMiniature_09,
                fullDesign: cinecinemaPicture_09,
            },
        ],
    },

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "sesame",
        name: "Sesame",
        id: "sesame",
        index: 15,
        microCover: sesame,
        microDescription_projectType: "Application CRM",
        microDescription_skillsUsed: "UX - UI Design",
        microDescription_year: 2022,
        microDescription_caseStudy: false,
        fullProjectName: "CRM pour utilisation interne",
        fullProjectDescription:
            "Sésame est un CRM dont j'ai pu refaire entièrement l'UX et l'UI pendant mon alternance à INSY2S. Ce CRM devait être suffisamment complet pour les employés n'ai quasiment pas besoin d'utiliser d'autres applications.",
        fullProjectIssue: [
            "Comprendre les besoins du client et de ses commerciaux en terme de recherches, démarches et ventes.",
            "Afficher un maximum d'informations à l'écran de manière claire, intuitive et en scrollant le moins possible.",
        ],
        fullProjectSolution: [
            "Mise en place d'un parcours utilisateurs, d'un userflow et échanges réguliers avec le client.",
            "Délimiter les espaces et utiliser un système d'emboitement pour plus de clarté.",
            "Recourir à un système de validation d'étapes pour que les commerciaux puissent visualiser rapidement la situation du client.",
            "Garder la navigation toujours visible pour un accès rapide.",
        ],
        sliderIntroPics: [sesameIntroDesktop],
        sliderIntroPicNbs: 1,
        sliderDesigns: [
            {
                id: 0,
                title: "Accueil",
                miniature: sesameMiniature_01,
                fullDesign: sesamePicture_01,
            },
            {
                id: 1,
                title: "Résultat de recherche",
                miniature: sesameMiniature_02,
                fullDesign: sesamePicture_02,
            },
            {
                id: 2,
                title: "Compte client - Localisation",
                miniature: sesameMiniature_03,
                fullDesign: sesamePicture_03,
            },
            {
                id: 3,
                title: "Information du compte",
                miniature: sesameMiniature_04,
                fullDesign: sesamePicture_04,
            },
            {
                id: 4,
                title: "Information du compte - Préparation",
                miniature: sesameMiniature_05,
                fullDesign: sesamePicture_05,
            },
            {
                id: 5,
                title: "Information du compte - Cotation",
                miniature: sesameMiniature_06,
                fullDesign: sesamePicture_06,
            },
            {
                id: 6,
                title: "Information du compte - Proposition",
                miniature: sesameMiniature_07,
                fullDesign: sesamePicture_07,
            },
            {
                id: 7,
                title: "Messagerie",
                miniature: sesameMiniature_08,
                fullDesign: sesamePicture_08,
            },
            {
                id: 8,
                title: "Documents",
                miniature: sesameMiniature_09,
                fullDesign: sesamePicture_09,
            },
        ],
    },

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "fujisport",
        name: "FujiSport",
        id: "FujiSport00",
        index: 14,
        microCover: fujisport,
        microDescription_projectType: "Site E-Commerce",
        microDescription_skillsUsed: "UX - UI Design",
        microDescription_year: 2019,
        microDescription_caseStudy: true,
        fullProjectName: "Refonte d'un Site E-Commerce",
        fullProjectDescriptionId: "description",
        fullProjectDescription:
            "FujiSport est une entreprise qui vend de l'équipement pour arts martiaux. Elle souhaite moderniser son image, améliorer le fonctionnement du site et gagner de nouveaux prospects.",
        fullProjectIssueId: "issues",
        fullProjectIssue: [
            "Dynamiser et moderniser l'image de la marque.",
            "Ne pas perdre de visiteurs avec un système trop complexe d'utilisation.",
            "Attirer les visiteurs sans aloudir l'interface.",
        ],
        fullProjectSolutionId: "solutions",
        fullProjectSolutionId2: "solutions2",
        fullProjectSolution: [
            "Refonte du logo",
            "Commande rapide pour les habitués qui savent exactement ce qu'ils veulent.",
            "Conseils complets, organisés par thème pour les débutants et semi-pros.",
            "Aider le dynamisme du site avec des promotions et l'arrivage de nouveaux produits.",
        ],
        sliderIntroPics: [
            fujisportIntroMobile,
            fujisportIntroTablet,
            fujisportIntroDesktop,
        ],
        sliderIntroPicNbs: 3,
        sliderDesigns: [
            {
                id: 0,
                title: "Accueil",
                miniature: fujisportMiniature_01,
                fullDesign: fujisportPicture_01,
            },
            {
                id: 1,
                title: "Guides",
                miniature: fujisportMiniature_02,
                fullDesign: fujisportPicture_02,
            },
            {
                id: 2,
                title: "Guides / Détails",
                miniature: fujisportMiniature_03,
                fullDesign: fujisportPicture_03,
            },
            {
                id: 3,
                title: "Articles / Détails",
                miniature: fujisportMiniature_04,
                fullDesign: fujisportPicture_04,
            },
            {
                id: 4,
                title: "Devis",
                miniature: fujisportMiniature_05,
                fullDesign: fujisportPicture_05,
            },
            {
                id: 5,
                title: "Panier",
                miniature: fujisportMiniature_06,
                fullDesign: fujisportPicture_06,
            },
            {
                id: 6,
                title: "Panier / Livraison",
                miniature: fujisportMiniature_07,
                fullDesign: fujisportPicture_07,
            },
            {
                id: 7,
                title: "Connexion",
                miniature: fujisportMiniature_08,
                fullDesign: fujisportPicture_08,
            },
            {
                id: 8,
                title: "Compte",
                miniature: fujisportMiniature_09,
                fullDesign: fujisportPicture_09,
            },
            {
                id: 9,
                title: "Personnalisation",
                miniature: fujisportMiniature_10,
                fullDesign: fujisportPicture_10,
            },
        ],
    },

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "desim",
        name: "Desim",
        id: "Desim01",
        index: 13,
        microCover: desim,
        microDescription_projectType: "Site E-Commerce",
        microDescription_skillsUsed: "UX - UI Design",
        microDescription_year: 2020,
        microDescription_caseStudy: false,
        fullProjectName: "Conception d'un Site hybride Vitrine / E-Commerce",
        fullProjectDescription:
            "DESIM est une entreprise de marquage qui réalise tout type de support de communication. Ses principaux axes de développement sont : les vêtements professionnels, le linge de maison, les accessoires (du stylo aux drapeaux en passant par les tasses et cartes de visite).",
        fullProjectIssue: [
            "Transmettre les valeurs de l'entreprise et faire comprendre sa plus-value face à la concurrence",
            "L'entreprise veut augmenter son nombre de commande mais doit pouvoir contrôler les devis avant validation. ( trop peu d'employés pour gérer toutes les commandes ) ",
        ],
        fullProjectSolution: [
            "Création d'un site web à mi-chemin entre le e-commerce et le site vitrine. Le coté e-commerce permet une présentation détaillée des produits proposés par l'entreprise. Le côté vitrine lui, met en avant ses valeurs, sa plus-value et détaille les techniques propore à son secteur d'activité. ",
            "La page Demande de devis permet à l'utilisateur de passer une commande directement online. Si l'entreprise accepte la commande, elle renvoit le détail du prix, la durée de production, les frais de livraison ainsi qu'un moyen de paiement.",
        ],
        sliderIntroPics: [
            desimIntroMobile,
            desimIntroTablet,
            desimIntroDesktop,
        ],
        sliderIntroPicNbs: 3,
        sliderDesigns: [
            {
                id: 0,
                title: "Accueil",
                miniature: desimMiniature_01,
                fullDesign: desimPicture_01,
            },
            {
                id: 1,
                title: "Articles",
                miniature: desimMiniature_02,
                fullDesign: desimPicture_02,
            },
            {
                id: 2,
                title: "Articles / Détails",
                miniature: desimMiniature_03,
                fullDesign: desimPicture_03,
            },
            {
                id: 3,
                title: "Parcours",
                miniature: desimMiniature_04,
                fullDesign: desimPicture_04,
            },
            {
                id: 4,
                title: "Techniques",
                miniature: desimMiniature_05,
                fullDesign: desimPicture_05,
            },
            {
                id: 5,
                title: "Devis",
                miniature: desimMiniature_06,
                fullDesign: desimPicture_06,
            },
            {
                id: 6,
                title: "Contact",
                miniature: desimMiniature_07,
                fullDesign: desimPicture_07,
            },
        ],
    },

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "ak2i",
        name: "AK2I Consulting",
        id: "ak2i02",
        index: 12,
        microCover: ak2i,
        microDescription_projectType: "Site Vitrine",
        microDescription_skillsUsed: "UX - UI Design",
        microDescription_year: 2021,
        microDescription_caseStudy: false,
        fullProjectName: "Design d'un One-Page",
        fullProjectDescription:
            "AK2I Consulting est une jeune entreprise d'accompagnement web. Elle propose des équipes et/ou des conseils adaptés en fonction de chaque projet.",
        fullProjectIssue: [
            "L'entreprise voulait absolument un site web au format One-page",
            "Présenter un maximum d'information dans un mimimum d'espace.",
        ],
        fullProjectSolution: [
            "Créer des sections clairement délimitées auxquelles accéder avec une ancre depuis le menu",
            "A l'intérieur d'une même section, créer des contenus qui puissent changer grâce à des onglets, des boutons ou des sliders ",
        ],
        sliderIntroPics: [ak2iIntroDesktop],
        sliderIntroPicNbs: 1,
        sliderDesigns: [
            {
                id: 0,
                title: "Accueil",
                miniature: ak2iMiniature_01,
                fullDesign: ak2iPicture_01,
            },
        ],
    },

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "sncf",
        name: "Oui.sncf",
        id: "sncf03",
        index: 11,
        microCover: sncf,
        microDescription_projectType: "Application Mobile",
        microDescription_skillsUsed: "Refonte d'App / UX - UI Design",
        microDescription_year: 2019,
        microDescription_caseStudy: true,
        fullProjectName: "Refonte de l'application",
        fullProjectDescription:
            "SNCF est l'entreprise ferroviaire la plus connue de France. Son application permet entres autres de réserver des billets directement en ligne. L'entreprise a souhaité changer l'apparence de son application pour la rendre plus dynamique.",
        fullProjectIssue: [
            "Créeer des animations et des transitions belles, dynamiques mais aussi viables à développer par la suite",
            "Adapter l'ensemble du contenu et des fonctionnalités à la refonte",
            "Garder la charte graphique",
        ],
        fullProjectSolution: [
            "Adapter les 2 couleurs principales pour les titres, les icones et la plupart des éléments interactifs",
            "Ajouter une animation en slider à l'écran d'Accueil avec des couleurs qui ressortent et rappellent le thème",
        ],
        sliderIntroPics: [sncfIntroMobile],
        sliderIntroPicNbs: 1,
        sliderDesigns: [
            {
                id: 0,
                title: "Accueil (part 1)",
                miniature: sncfMiniature_01,
                fullDesign: sncfPicture_01,
            },
            {
                id: 1,
                title: "Accueil (part 2)",
                miniature: sncfMiniature_02,
                fullDesign: sncfPicture_02,
            },
        ],
    },

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "frisson",
        name: "Frisson",
        id: "frisson04",
        index: 10,
        microCover: frisson,
        microDescription_projectType: "Ecran de frigo connecté",
        microDescription_skillsUsed: "UX - UI Design",
        microDescription_year: 2020,
        microDescription_caseStudy: true,
        fullProjectName: "Refonte d'un Site E-Commerce",
        fullProjectDescription:
            "Nous sommes en 2030. La gestion de la nourriture est toujours plus essentielle et les réfrigérateurs connectés se sont démocratisés. La France décide de s'imposer sur le marché en proposant un tout nouveau modèle 'French Touch', éco-responsable et accessible à tous.",
        fullProjectIssue: [
            "Le style graphique doit être futuriste",
            "L'application doit présenter le contenu du réfrigérateur et ce dernier doit pouvoir être trié par filtre",
        ],
        fullProjectSolution: [
            "Inspiration de différents designs dont le retro-gaming.",
            "Utiliser des sliders que l'on peut manipuler facilement pour trier un maximum d'informations.",
            "Utiliser des sections avec boutons en forme d'icones pour trier le contenu du frigo.",
        ],
        sliderIntroPics: [frissonIntroTablet],
        sliderIntroPicNbs: 1,
        sliderDesigns: [
            {
                id: 0,
                title: "Ecran de veille",
                miniature: frissonMiniature_01,
                fullDesign: frissonPicture_01,
            },
            {
                id: 1,
                title: "Accueil",
                miniature: frissonMiniature_02,
                fullDesign: frissonPicture_02,
            },
            {
                id: 2,
                title: "Contenu du frigo",
                miniature: frissonMiniature_03,
                fullDesign: frissonPicture_03,
            },
        ],
    },

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "iacess",
        name: "Iacess",
        id: "Iacess05",
        index: 9,
        microCover: iacess,
        microDescription_projectType: "Site Vitrine",
        microDescription_skillsUsed: "UX - UI Design/Intégration",
        microDescription_year: 2020,
        microDescription_caseStudy: false,
        fullProjectName: "Conception du site d'une association",
        fullProjectDescription:
            "IACESS est une association citoyenne. Son acronyme signifie : Interdépendance, Altruisme, Coopération, Écologie et Spiritualité Scientifique.",
        fullProjectIssue: [
            "Faire connaitre l'association et ses valeurs",
            "Permettre aux visiteurs de participer à une levée de fond et d'acheter directement en ligne.",
        ],
        fullProjectSolution: [
            "Intégrer une solution e-commerce qui permettra de commander directement depuis le site web.",
            "Expliquer le logo, les démarches de l'association, etc., tout en gardant une interface très simple, casiment One-Page.",
        ],
        sliderIntroPics: [
            iacessIntroMobile,
            iacessIntroTablet,
            iacessIntroDesktop,
        ],
        sliderIntroPicNbs: 3,
        sliderDesigns: [
            {
                id: 0,
                title: "Accueil",
                miniature: iacessMiniature_01,
                fullDesign: iacessPicture_01,
            },
            {
                id: 1,
                title: "Évenèments",
                miniature: iacessMiniature_02,
                fullDesign: iacessPicture_02,
            },
        ],
    },

    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "circularkitchen",
        name: "Cuisine Circulaire",
        subName: "Une cuisine circulaire",
        id: "circularKitchen07",
        index: 8,
        microCover: circularKitchen,
        microDescription_projectType: "3D _ Image de synthèse",
        microDescription_skillsUsed: "Modélisation, lumières, textures",
        microDescription_year: 2018,
        microDescription_caseStudy: true,
        fullProjectName: "(Entièrement en images de synthèse)",
        fullProjectDescription: [
            "Cet aperçu de cuisine a été réalisé avec 3ds Max, Photoshop et Vray. Le projet fait parti de la preview d'un appartement circulaire.",
        ],
        renderedImgOrVid: [cKPicture_01],
        renderedImgOrVidAlts: ["Une cuisine circulaire"],
    },

    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "squarekitchen",
        name: "Cuisine Carrée",
        subName: "Une cuisine encastrée",
        id: "squareKitchen08",
        index: 7,
        microCover: squareKitchen,
        microDescription_projectType: "3D _ Image de synthèse",
        microDescription_skillsUsed: "Modélisation, lumières, textures",
        microDescription_year: 2018,
        microDescription_caseStudy: false,
        fullProjectName: "(Entièrement en images de synthèse)",
        fullProjectDescription: [
            "Cet aperçu de cuisine a été réalisé avec 3ds Max, Photoshop et Vray. Le projet a pour but de montrer à de futurs clients à quoi pourrait ressembler leur future cuisine encastrée.",
        ],
        renderedImgOrVid: [sKPicture_01],
        renderedImgOrVidAlts: ["Cuisine en 3D image de synthèse"],
    },

    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "circularloft",
        name: "Loft Circulaire",
        subName: "Le plan 3D de la cuisine circulaire",
        id: "circularLoft09",
        index: 6,
        microCover: circularLoft,
        microDescription_projectType: "3D _ Image de synthèse",
        microDescription_skillsUsed: "Modélisation, lumières, textures",
        microDescription_year: 2018,
        microDescription_caseStudy: true,
        fullProjectName: "(Entièrement en images de synthèse)",
        fullProjectDescription: [
            "Ce plan en relief a été réalisé avec 3ds Max, Photoshop et Vray. Le projet fait parti de la preview d'un appartement circulaire.",
        ],
        renderedImgOrVid: [cLPicture_01],
        renderedImgOrVidAlts: [
            "plan en coupe d'une cuisine ronde en image de synthèse 3D",
        ],
    },

    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "carsrace",
        name: "Course de voitures",
        subName: "Course de petites voitures",
        id: "carsRacing10",
        index: 5,
        microCover: carsRacing,
        microDescription_projectType: "3D _ Image de synthèse",
        microDescription_skillsUsed:
            "Incrustation photographique, Modélisation, lumières, textures",
        microDescription_year: 2017,
        microDescription_caseStudy: true,
        fullProjectName: "(Prise de vue réelle et images de synthèse)",
        fullProjectDescription: [
            "Cet incrustation a été réalisé avec 3ds Max, Photoshop, Vray et Nuke. Le projet a pour but de donner vie à une photo.",
        ],
        renderedImgOrVid: ["https://player.vimeo.com/video/259313199"],
        renderedImgOrVidAlts: ["Une course de petites voitures en 3D"],
    },

    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "dojo",
        name: "Dojo ou Magasin",
        subName: "Le dojo devenu magasin",
        id: "dojo11",
        index: 4,
        microCover: dojo,
        microDescription_projectType: "3D _ Image de synthèse",
        microDescription_skillsUsed:
            "Animation de Caméra, Modélisation, lumières, textures",
        microDescription_year: 2017,
        microDescription_caseStudy: true,
        fullProjectName: "(Entièrement en images de synthèse)",
        fullProjectDescription: [
            "Cette animation a été réalisé avec 3ds Max, Vray, Photoshop, Substance Painter et Nuke. Le projet est axé autour du travail des lumières et des matières. C'est également un entrainement dans l'utilisation des mouvements de caméra et du timing.",
            "Cette courte vidéo montre un aperçu des étapes de la création du projet. On voit une superposition des lumières présentes dans la scène ainsi que quelques calques utilisés pour la post-production.",
        ],
        renderedImgOrVid: [
            "https://player.vimeo.com/video/259312599",
            "https://player.vimeo.com/video/259312793",
        ],
        renderedImgOrVidAlts: [
            "Un dojo en 3D avec de l'équipement sportif à vendre",
            "Breakdown du dojo en 3D",
        ],
    },

    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "lanterns",
        name: "Lanternes",
        subName: "Nuit étoilée à la lueur des lanternes",
        id: "lanterns12",
        index: 3,
        microCover: lanterns,
        microDescription_projectType: "3D _ Image de synthèse",
        microDescription_skillsUsed:
            "Animation de Caméra, Modélisation, lumières, textures",
        microDescription_year: 2018,
        microDescription_caseStudy: true,
        fullProjectName: "(Entièrement en images de synthèse)",
        fullProjectDescription: [
            "Cette animation a été réalisé avec 3ds Max, Vray, Photoshop et Nuke. Le projet est axé autour du travail des lumières et des matières.",
            "La vidéo montre un aperçu des étapes de la création du projet. On voit une superposition des lumières présentes dans la scène ainsi que quelques calques utilisés pour la post-production.",
        ],
        renderedImgOrVid: [
            "https://player.vimeo.com/video/259304106",
            "https://player.vimeo.com/video/259304441",
        ],
        renderedImgOrVidAlts: [
            "Des lanternes éclairées sur l'eau et sous un arbre",
            "Breakdown des lanternes éclairées",
        ],
    },

    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "stilllife",
        name: "Nature Morte",
        subName: "Nature Morte : ",
        id: "stillLife14",
        index: 2,
        microCover: stillLife,
        microDescription_projectType: "3D _ Image de synthèse",
        microDescription_skillsUsed: "Modélisation, lumières, textures",
        microDescription_year: 2016,
        microDescription_caseStudy: true,
        fullProjectName: "De classique à graphique",
        fullProjectDescription: [
            "L'image a été réalisé avec 3ds Max, Vray, Photoshop et Nuke. Ce projet est le premier réalisé avec 3dsMax. Il avait pour but, dans un premier temps, de bien prendre en même le logiciel.",
            "Dans un second temps, le projet devait évoluer et être plus graphique.",
        ],
        renderedImgOrVid: [sLPicture_01, sLPicture_02],
        renderedImgOrVidAlts: [
            "Une nature morte de petits objets sur fond gris",
            "Une nature morte de petits objets sur un fond en bois",
        ],
    },

    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "knight",
        name: "Cavalier",
        subName: "Modélisation 3D d'une pièce d'échiquier",
        id: "knight15",
        index: 1,
        microCover: knight,
        microDescription_projectType: "3D _ Image de synthèse",
        microDescription_skillsUsed: "Modélisation, lumières, textures",
        microDescription_year: 2016,
        microDescription_caseStudy: true,
        fullProjectName: "(Entièrement en images de synthèse)",
        fullProjectDescription: [
            "L'image a été réalisé avec 3ds Max, Vray, Z Brush, Photoshop et Nuke. Le projet est un exercice multiple. Il s'axe autour de la mise en valeur du sujet par le cadrage et la profondeur de champ ainsi que les lumières et les textures.",
        ],
        renderedImgOrVid: [knightPicture_01],
        renderedImgOrVidAlts: [
            "Image d'une pièce d'échiquier en image de synthèse 3D",
        ],
    },

    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////
    //     //////////////////////////////////////////////////////////////////////////////

    {
        urlName: "origami",
        name: "Origami",
        subName: "Origami dans la rivière",
        id: "origami16",
        index: 0,
        microCover: origami,
        microDescription_projectType: "3D _ Image de synthèse",
        microDescription_skillsUsed:
            "Incrustation photographique, Modélisation, lumières, textures",
        microDescription_year: 2017,
        microDescription_caseStudy: true,
        fullProjectName: "(Prise de vue réelle et images de synthèse)",
        fullProjectDescription: [
            "Cette incrustation a été réalisé avec 3ds Max, Photoshop, Vray et Nuke.",
            "La vidéo montre un aperçu des étapes de la création du projet. On voit une superposition des lumières et des calques utilisés pour la post-production.",
        ],
        renderedImgOrVid: [
            "https://player.vimeo.com/video/259303114",
            "https://player.vimeo.com/video/259303305",
        ],
        renderedImgOrVidAlts: [
            "Une ville en papier plié sur une rivière",
            "Breakdown de la scène",
        ],
    },
];

export default projetcsDatas;
