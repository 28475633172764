import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../../fixedComponents/Home/Home";
import Projects from "../../fixedComponents/Projects/Projects";
import ProjectDetails from "../ProjectDetails/ProjectDetails";
import Contact from "../../fixedComponents/Contact/Contact";
import Profile from "../../fixedComponents/Profile/Profile";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/projects/:web"
                    element={<ProjectDetails pageLink="/projects/:web" />}
                />
                <Route
                    path="/projects"
                    element={<Projects pageLink="/projects" />}
                />
                <Route
                    path="/profile"
                    element={<Profile pageLink="/profile" />}
                />
                <Route
                    path="/contact"
                    element={<Contact pageLink="/contact" />}
                />
                <Route exact path="/" element={<Home pageLink="/" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
